.order-item {
	border: 1px solid rgb(10, 90, 19);
	padding: 20px 10px;
	border-radius: 5px;
	width: 100%;
}

.order-item summary {
	font-weight: 600;
	font-size: 16px;
	padding: 10px 0;
}

.order-item h5, .order-item p {
	display: inline-block;
	margin: 0;
}

.order-item h5 {
	color: #6b6868;
	font-weight: 600;
	text-transform: capitalize;
	font-size: 16px;
	width: 250px;
}

.order-item p {
	color: #333;
	font-weight: 600;
	font-size: 16px;
}

.order-show-button {
	background-color: #2f513e;
	color: #fff;
	border: 0 none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	margin-top: 5px;
}

.update-button {
	background-color: #2f513e;
	color: #fff;
	border: 0 none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	margin-top: 5px;
}



.orders-div select {
	background-color: #2f4351;
	color: #fff;
	border: 0 none;
	border-radius: 5px;
	padding: 0.5em 1em;
	cursor: pointer;
}

.order {
	display: flex;
}
	
.orders {
	display: flex;
	flex-direction: column;
}
.order-update {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	border: 1px solid rgb(10, 90, 19);
	padding: 20px 10px;
	border-radius: 5px;
	}

	.generate-shopping-list-button {
		background-color: #2a0336;
		color: #fff;
		border: 0 none;
		border-radius: 5px;
		padding: 10px 20px;
		cursor: pointer;
		margin-top: 5px;
	}

	.pagination-orders {
		display: flex;
		justify-content: space-between;
		padding: 1	5px 0;
	}
	
	.pagination-orders button {
		background: none;
		border: none;
		margin: 0 5px;
		padding: 15px;
		cursor: pointer;
		font-size: large;
	}
	
	.pagination-orders button.active {
		font-weight: bold;
		text-decoration: underline;
	}

	.pagination-orders button:hover {
		font-weight: bold;
		text-decoration: underline;
	}

	.pagination-orders select {
		cursor: pointer;
		font-size: large;
	}

	.pagination-orders select:hover {
		font-weight: bold;
		text-decoration: underline;
	}

	.page-size {
		display: flex;
		align-items: center;
	}