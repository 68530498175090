
.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 100%;
  padding: 50px;
  margin: auto auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

input {
  margin-top: 5px;
  padding: 10px;
  width: 90%;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

form button:hover {
  background-color: #0056b3;
}

form button:active {
  background-color: #003580;
}

form button:disabled {
  background-color: #7a7a7a;
  cursor: not-allowed;
}

.form-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}