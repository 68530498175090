/* Client.css */

.client-container {
  padding: 20px 10px;
  width: stretch;
  
}

h1 {
  color: #333;
  font-size: 2em;
  text-align: start;
  margin-bottom: 1em;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

table thead {
  background-color: #f5f5f5;
}

table th, table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table tr:hover {
  background-color: #f5f5f5;
}

table td {
  font-size: 0.875em;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}

.pagination button {
  background: none;
  border: none;
  margin: 0 5px;
  padding: 15px;
  cursor: pointer;
  font-size: large;
}

.pagination button.active {
  font-weight: bold;
  text-decoration: underline;
}