.promo-codes-container {
  padding: 30px;
  /* background-color: #f9f9f9; */
  display: flex;
  flex-direction: column;
}

.promo-code-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  }

.promo-code-form select {
  width: 50%; /* Allows two fields per row */
  padding: 8px 0;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.promo-code-form label {
  width: 50%; /* Allows two fields per row */
  padding: 8px 0;
}
.promo-code-form input {
  width: 50%; /* Allows two fields per row */
  padding: 8px 0;
  padding-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.promo-code-form button {
  padding: 10px 15px;
  background-color: #154826;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: large;
}

.promo-code-form button:hover {
  background-color: #33c678;
}


.promo-codes-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.promo-codes-table button{
  padding: 10px 20px;
  background-color: #154826;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.promo-code-table button:hover {
  background-color: #33c678;
}

.promo-codes-table th, .promo-codes-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.promo-codes-table th {
  background-color: #279b67;
}

.promo-codes-table tr:hover {
  background-color: #f5f5f5;
}

/* ... existing styles ... */

.notification {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
