.settings-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.settings-container h2, .settings-container h3 {
  font-size: 20px;
  margin-bottom: 15px;
}


.settings-header {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.settings-label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
}

.settings-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 20px;
}

.settings-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.settings-button:hover {
  background-color: #0056b3;
}
