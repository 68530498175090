.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
}

.label {
  margin-bottom: 0.5rem;
}

.input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.submit-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  background-color: #007BFF;
  color: #fff;
  cursor: pointer;
}

.instructions {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: grid;
  margin-bottom: 1rem;
}
.instructions:not(:last-child) {
  margin-bottom: 1rem;
}
