/* SearchPage.css */

.container {
  margin: 0 auto;
  padding: 40px 80px;
  max-width: 1200px;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

table thead {
  background-color: #f5f5f5;
}

table th, table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table tr:hover {
  background-color: #f5f5f5;
}

table td {
  font-size: 0.875em;
}

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.searchBar
select {
  margin-left: 10px;
  padding: 12px;
  border-radius: 5px;
  border: none;
  background-color: #0a8a37;
  color: white;
  cursor: pointer;
}
