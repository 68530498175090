.ingredients-container {
  padding: 20px;
}

.ingredients-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.ingredients-table {
  width: 100%;
  border-collapse: collapse;
}

.ingredients-table th, .ingredients-table td {
  padding: 8px 12px;
  border: 1px solid #ccc;
}

.update-button {
  padding: 6px 12px;
  background-color: #007BFF;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.update-button:hover {
  background-color: #0056b3;
}

.update-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.ingredients-table
select {
  margin-left: 1px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #0a8a37;
  color: white;
  cursor: pointer;
}