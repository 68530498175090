.recipes {
	padding: 40px 20px;
	margin: 0 auto;
}

.recipe {
	display: flex;
	position: relative;
}

.recipe-image{
	position: absolute;
	border-radius: 5px;
	overflow: hidden;
	width: 240px;
	height: auto;
	top: 1px;
	right: 1px;
}

.recipe-item {
	border: 1px solid rgb(10, 90, 19);
	padding: 20px 10px;
	border-radius: 5px;
	width: 100%;
}

.recipe-item summary {
	font-weight: 600;
	font-size: 16px;
	padding: 10px 0;
}

.recipe-item h5, .recipe-item p {
	display: inline-block;
	margin: 0;
	margin-bottom: 6px;
}

.recipe-item h5 {
	color: #6b6868;
	font-weight: 600;
	text-transform: capitalize;
	font-size: 16px;
	width: 250px;
}

.recipe-item p {
	color: #333;
	font-weight: 600;
	font-size: 16px;
}

.update-button {
	background-color: #2f513e;
	color: #fff;
	border: 0 none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	margin: 5px;
	font-size: 16px;
}

.delete-button {
	background-color: rgb(124, 9, 9);
	color: #fff;
	border: 0 none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	margin: 5px;
	font-size: 16px;
}

.recipe-footer{
	display: flex;
	justify-content: flex-end;
}

.ingredient-name{
	margin-right: 10px;
	text-decoration: underline;
	cursor: pointer;
}
.ingredient-name:hover{
	opacity: 0.8;
	color: red;
}