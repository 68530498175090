.side-bar {
  position: sticky; /* Makes the sidebar sticky */
  top: 0; /* The sidebar will stick after scrolling 0px */
  height: 95vh; /* Makes the sidebar full height */
  display: flex;
  flex-direction: column;
  margin: 1.5em 0em;
  justify-content: space-between;
  border: 2px solid #2f513e;
  background-color: rgb(160, 203, 117);
  box-shadow: inset 0 0 10px #c8f469;
  width: 40px;

}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 0.5em;
  text-align: start;
}



.logout-button {
  margin-bottom: 0.5em;
  cursor: pointer;
  background-color: inherit;
  border-radius: 50%;
  border: 0 none;
}


.logo-icon {
  float: left;
  position: absolute;
  padding-bottom: 20px;
  margin-bottom: 20px;
  }