.editor-modal_backdrop {
	background: #33333333;
	position: fixed;
	inset: 0;
	z-index: 99;
}

.editor-modal_wrapper {
	background-color: #eee;
	border: 1px solid #ccc;
	border-radius: 8px;
	position: fixed;
	z-index: 100;
	overflow-y: auto;
	width: 500px;
	top: 0;
	bottom: 0;
	right: 0;
	transform: translateX(100%);
	padding: 40px 20px;
	transition: all 500ms ease;
}

.editor-modal_wrapper.open {
	transform: translateX(0);
}