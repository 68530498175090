.form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 80%;
  max-width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.label {
font-weight: bold;
}

.input, .select {
margin: 10px 0;
padding: 5px;
}

.translation-block {
border: 1px solid #ddd;
border-radius: 5px;
padding: 10px;
margin: 10px 0;
display: flex;
flex-direction: column;
/* align-items: center; */
}

.add-button, .remove-button, .submit-button {
margin-top: 10px;
padding: 5px 10px;
border: none;
border-radius: 5px;
cursor: pointer;
}

.add-button {
background-color: #0f0;
}

.remove-button {
background-color: #f00;
color: #fff;
}

.submit-button {
background-color: #00f;
color: #fff;
}
